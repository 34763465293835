<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="schedule">
		<div class="schedule-inform">
			注：上传的课表必须真实，方便相关人员进行查看
		</div>
		<img class="schedule-img" v-if="scheduleUrl" :src="scheduleUrl" alt="">
		<div @click="uploadSchedule" v-if="!scheduleUrl" class="schedule-upload">
			<img src="@/assets/images/mobile/common/plus.png" alt="">
			<span>点击上传图片</span>
		</div>
		<div @click="uploadSchedule" v-if="scheduleUrl" class="schedule-btn">
			重新上传
		</div>
		<el-upload class="uploadImage" style="display:none;" :action="upLoadUrl" :show-file-list="false"
			:before-upload="beforeUpload" :http-request="handleCustomRequest"></el-upload>
	<!-- 	<tooltip @confirm="confirm" @cancle="cancle" :isShowToolTip="isShowToolTip" :tooltipTitle="'温馨提示'"
			:tooltipContent="'确定要删除该课表吗？'" /> -->
	</div>
</template>
<script>
	import {
		upLoadFileMb
	} from "@/api/mobile/oss"
	import {
		studentAccountEditMb,
		deleteScheduleMb,
	} from "@/api/mobile/mine"
	import {
		mapActions
	} from 'vuex'
	import storage from 'store'
	export default {
		data() {
			return {
				upLoadUrl: "",
				scheduleUrl: "",
				canUpload: true,
				limitSize: 5,
				isShowToolTip: false
			}
		},
		mounted() {
			this.scheduleUrl = storage.get("accessInfo").curriculum
		},
		methods: {
			...mapActions(['mbUserInfo']),
			// confirm() {
			// 	deleteScheduleMb().then(async res => {
			// 		if (res.code == 0) {
			// 			this.$toast("课表删除成功")
			// 			this.isShowToolTip = false
			// 			await this.mbUserInfo()
			// 			this.scheduleUrl = storage.get("accessInfo").curriculum
			// 		}
			// 	})
			// },
			// cancle() {
			// 	this.isShowToolTip = false
			// },
			// deleteSchedule() {
			// 	this.isShowToolTip = true
			// },
			uploadSchedule() {
				if (!this.canUpload) {
					return false
				}
				// document.getElementById('scheduleFile').click()
				document.querySelector(".uploadImage input").click();
			},
			// 上传按钮   限制图片大小
			beforeUpload(file) {
				const isLt5M = file.size / 1024 / 1024 < this.limitSize;
				if (!isLt5M) {
					this.$toast("上传文件大小不能超过 5MB!")
					return false;
				}
				let fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
				let fileName = file.name;
				// let fileType = file.type;
				console.log("beforeUpload", fileType);
				if (fileType != "png" && fileType != "jpg" && fileType != "jpeg" && fileType != "webp") {
					this.$toast("上传文件只能是jpg、png、jpeg、webp格式!")
					return false;
				}
			},
			handleCustomRequest(data) {
				console.log("handleCustomRequest", data)
				this.fileData = []
				const formData = new FormData();
				formData.append('file', data.file);
				upLoadFileMb(formData).then((res) => {
					console.log("上传成功", res)
					let params = {
						curriculum: res.data.accessUrl,
					}
					studentAccountEditMb(params).then(async (res) => {
						if (res.code == 0) {
							this.$toast("课表上传成功")
							await this.mbUserInfo()
							this.scheduleUrl = storage.get("accessInfo").curriculum
						}
					})
				})
			},
		},
	}
</script>
<style lang="scss" scoped>
	.schedule {
		display: flex;
		flex-direction: column;

		.schedule-inform {
			line-height: 0.66rem;
			background: #FDF5E3;
			padding: 0 0.28rem;
			box-sizing: border-box;
			font-size: 0.24rem;
			font-family: PingFang SC;
			font-weight: 400;
			color: #FFAE00;
		}

		.schedule-img {
			width: 6.94rem;
			height: 8rem;
			border-radius: 0.16rem;
			margin: 0.28rem auto;
		}

		.schedule-upload {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 6.94rem;
			height: 8rem;
			background: #FFFFFF;
			border-radius: 0.16rem;
			margin: 0.28rem auto;

			img {
				width: 1.2rem;
				height: 1.2rem;
				margin-bottom: 0.28rem;
			}

			span {
				font-size: 0.28rem;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 0.4rem;
				color: #B4BCC6;
			}
		}

		.schedule-btn {
			width: 6.94rem;
			line-height: 0.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #4392FA;
			border-radius: 0.08rem;
			font-size: 0.28rem;
			font-family: PingFang SC;
			font-weight: bold;
			color: #FFFFFF;
			margin: 0.36rem auto 0 auto;
		}
	}
</style>
